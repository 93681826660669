// for getting list of groupchats
export const USER_GET_GROUPCHATS_REQUEST = "USER_GET_GROUPCHATS_REQUEST";
export const USER_GET_GROUPCHATS_SUCCESS = "USER_GET_GROUPCHATS_SUCCESS";
export const USER_GET_GROUPCHATS_FAIL = "USER_GET_GROUPCHATS_FAIL";
export const USER_GET_GROUPCHATS_RESET = "USER_GET_GROUPCHATS_RESET";

// For getting the list of groupchats a user is a part of
export const GET_USER_GROUPCHATS_REQUEST = "GET_USER_GROUPCHATS_REQUEST";
export const GET_USER_GROUPCHATS_SUCCESS = "GET_USER_GROUPCHATS_SUCCESS";
export const GET_USER_GROUPCHATS_FAIL = "GET_USER_GROUPCHATS_FAIL";
export const GET_USER_GROUPCHATS_RESET = "GET_USER_GROUPCHATS_RESET";

export const USER_JOIN_GROUPCHAT_REQUEST = "USER_JOIN_GROUPCHAT_REQUEST";
export const USER_JOIN_GROUPCHAT_SUCCESS = "USER_JOIN_GROUPCHAT_SUCCESS";
export const USER_JOIN_GROUPCHAT_FAIL = "USER_JOIN_GROUPCHAT_FAIL";
export const USER_JOIN_GROUPCHAT_RESET = "USER_JOIN_GROUPCHAT_RESET";

export const USER_GET_MESSAGES_REQUEST = "USER_GET_MESSAGES_REQUEST";
export const USER_GET_MESSAGES_SUCCESS = "USER_GET_MESSAGES_SUCCESS";
export const USER_GET_MESSAGES_FAIL = "USER_GET_MESSAGES_FAIL";
export const USER_GET_MESSAGES_RESET = "USER_GET_MESSAGES_RESET";

export const USER_SEND_MESSAGE_REQUEST = "USER_SEND_MESSAGE_REQUEST";
export const USER_SEND_MESSAGE_SUCCESS = "USER_SEND_MESSAGE_SUCCESS";
export const USER_SEND_MESSAGE_FAIL = "USER_SEND_MESSAGE_FAIL";
export const USER_SEND_MESSAGE_RESET = "USER_SEND_MESSAGE_RESET";

export const USER_LIKE_MESSAGE_REQUEST = "USER_LIKE_MESSAGE_REQUEST";
export const USER_LIKE_MESSAGE_SUCCESS = "USER_LIKE_MESSAGE_SUCCESS";
export const USER_LIKE_MESSAGE_FAIL = "USER_LIKE_MESSAGE_FAIL";
export const USER_LIKE_MESSAGE_RESET = "USER_LIKE_MESSAGE_RESET";

export const USER_GET_GROUPCHAT_DETAILS_REQUEST =
  "USER_GET_GROUPCHAT_DETAILS_REQUEST";
export const USER_GET_GROUPCHAT_DETAILS_SUCCESS =
  "USER_GET_GROUPCHAT_DETAILS_SUCCESS";
export const USER_GET_GROUPCHAT_DETAILS_FAIL =
  "USER_GET_GROUPCHAT_DETAILS_FAIL";
export const USER_GET_GROUPCHAT_DETAILS_RESET =
  "USER_GET_GROUPCHAT_DETAILS_RESET";

export const USER_GET_GROUPCHAT_MEMBERS_REQUEST =
  "USER_GET_GROUPCHAT_MEMBERS_REQUEST";
export const USER_GET_GROUPCHAT_MEMBERS_SUCCESS =
  "USER_GET_GROUPCHAT_MEMBERS_SUCCESS";
export const USER_GET_GROUPCHAT_MEMBERS_FAIL =
  "USER_GET_GROUPCHAT_MEMBERS_FAIL";
export const USER_GET_GROUPCHAT_MEMBERS_RESET =
  "USER_GET_GROUPCHAT_MEMBERS_RESET";
