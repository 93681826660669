export const USER_CREATE_REVIEW_REQUEST = "USER_CREATE_REVIEW_REQUEST";
export const USER_CREATE_REVIEW_SUCCESS = "USER_CREATE_REVIEW_SUCCESS";
export const USER_CREATE_REVIEW_FAIL = "USER_CREATE_REVIEW_FAIL";
export const USER_CREATE_REVIEW_RESET = "USER_CREATE_REVIEW_RESET";

export const USER_REVIEW_STAT_REQUEST = "USER_REVIEW_STAT_REQUEST";
export const USER_REVIEW_STAT_SUCCESS = "USER_REVIEW_STAT_SUCCESS";
export const USER_REVIEW_STAT_FAIL = "USER_REVIEW_STAT_FAIL";
export const USER_REVIEW_STAT_RESET = "USER_REVIEW_STAT_RESET";

export const USER_LIST_REVIEW_REQUEST = "USER_LIST_REVIEW_REQUEST";
export const USER_LIST_REVIEW_SUCCESS = "USER_LIST_REVIEW_SUCCESS";
export const USER_LIST_REVIEW_FAIL = "USER_LIST_REVIEW_FAIL";
export const USER_LIST_REVIEW_RESET = "USER_LIST_REVIEW_RESET";
