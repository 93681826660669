export const ADMIN_CREATE_SERVICE_REQUEST = "ADMIN_CREATE_SERVICE_REQUEST";
export const ADMIN_CREATE_SERVICE_SUCCESS = "ADMIN_CREATE_SERVICE_SUCCESS";
export const ADMIN_CREATE_SERVICE_FAIL = "ADMIN_CREATE_SERVICE_FAIL";
export const ADMIN_CREATE_SERVICE_RESET = "ADMIN_CREATE_SERVICE_RESET";

export const ADMIN_UPDATE_SERVICE_REQUEST = "ADMIN_UPDATE_SERVICE_REQUEST";
export const ADMIN_UPDATE_SERVICE_SUCCESS = "ADMIN_UPDATE_SERVICE_SUCCESS";
export const ADMIN_UPDATE_SERVICE_FAIL = "ADMIN_UPDATE_SERVICE_FAIL";
export const ADMIN_UPDATE_SERVICE_RESET = "ADMIN_UPDATE_SERVICE_RESET";

export const ADMIN_DELETE_SERVICE_REQUEST = "ADMIN_DELETE_SERVICE_REQUEST";
export const ADMIN_DELETE_SERVICE_SUCCESS = "ADMIN_DELETE_SERVICE_SUCCESS";
export const ADMIN_DELETE_SERVICE_FAIL = "ADMIN_DELETE_SERVICE_FAIL";
export const ADMIN_DELETE_SERVICE_RESET = "ADMIN_DELETE_SERVICE_RESET";

export const ADMIN_GETBYID_SERVICE_REQUEST = "ADMIN_GETBYID_SERVICE_REQUEST";
export const ADMIN_GETBYID_SERVICE_SUCCESS = "ADMIN_GETBYID_SERVICE_SUCCESS";
export const ADMIN_GETBYID_SERVICE_FAIL = "ADMIN_GETBYID_SERVICE_FAIL";
export const ADMIN_GETBYID_SERVICE_RESET = "ADMIN_GETBYID_SERVICE_RESET";

export const ADMIN_GET_ALL_SERVICE_REQUEST = "ADMIN_GETBYALL_SERVICE_REQUEST";
export const ADMIN_GET_ALL_SERVICE_SUCCESS = "ADMIN_GETBYALL_SERVICE_SUCCESS";
export const ADMIN_GET_ALL_SERVICE_FAIL = "ADMIN_GETBYALL_SERVICE_FAIL";
export const ADMIN_GET_ALL_SERVICE_RESET = "ADMIN_GETBYALL_SERVICE_RESET";
