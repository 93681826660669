export const UPDATE_TERMS_REQUEST = "UPDATE_TERMS_REQUEST";
export const UPDATE_TERMS_SUCCESS = "UPDATE_TERMS_SUCCESS";
export const UPDATE_TERMS_FAIL = "UPDATE_TERMS_FAIL";
export const UPDATE_TERMS_RESET = "EDIT_TERMS_RESET";

export const CREATE_SERVICE_ORDER_REQUEST = "CREATE_SERVICE_ORDER_REQUEST";
export const CREATE_SERVICE_ORDER_SUCCESS = "CREATE_SERVICE_ORDER_SUCCESS";
export const CREATE_SERVICE_ORDER_FAIL = "CREATE_SERVICE_ORDER_FAIL";
export const CREATE_SERVICE_ORDER_RESET = "CREATE_SERVICE_ORDER_RESET";

export const GET_SERVICE_ORDER_BYID_REQUEST = "GET_SERVICE_ORDER_BYID_REQUEST";
export const GET_SERVICE_ORDER_BYID_SUCCESS = "GET_SERVICE_ORDER_BYID_SUCCESS";
export const GET_SERVICE_ORDER_BYID_FAIL = "GET_SERVICE_ORDER_BYID_FAIL";
export const GET_SERVICE_ORDER_BYID_RESET = "GET_SERVICE_ORDER_BYID_RESET";

export const GET_SERVICE_ORDERS_REQUEST = "GET_SERVICE_ORDERS_REQUEST";
export const GET_SERVICE_ORDERS_SUCCESS = "GET_SERVICE_ORDERS_SUCCESS";
export const GET_SERVICE_ORDERS_FAIL = "GET_SERVICE_ORDERS_FAIL";
export const GET_SERVICE_ORDERS_RESET = "GET_SERVICE_ORDERS_RESET";

export const UPDATE_SERVICE_ORDERS_REQUEST = "UPDATE_SERVICE_ORDERS_REQUEST";
export const UPDATE_SERVICE_ORDERS_SUCCESS = "UPDATE_SERVICE_ORDERS_SUCCESS";
export const UPDATE_SERVICE_ORDERS_FAIL = "UPDATE_SERVICE_ORDERS_FAIL";
export const UPDATE_SERVICE_ORDERS_RESET = "UPDATE_SERVICE_ORDERS_RESET";
