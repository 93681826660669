export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL";
export const ADD_TO_CART_RESET = "ADD_TO_CART_RESET";

export const VIEW_CART_REQUEST = "VIEW_CART_REQUEST";
export const VIEW_CART_SUCCESS = "VIEW_CART_SUCCESS";
export const VIEW_CART_FAIL = "VIEW_CART_FAIL";
export const VIEW_CART_RESET = "VIEW_CART_RESET";

export const UPDATE_CART_REQUEST = "UPDATE_CART_REQUEST";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_FAIL = "UPDATE_CART_FAIL";
export const UPDATE_CART_RESET = "UPDATE_CART_RESET";

export const REMOVE_FROM_CART_REQUEST = "REMOVE_FROM_CART_REQUEST";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_FAIL = "REMOVE_FROM_CART_FAIL";
export const REMOVE_FROM_CART_RESET = "REMOVE_FROM_CART_RESET";

export const CLEAR_CART_REQUEST = "CLEAR_CART_REQUEST";
export const CLEAR_CART_SUCCESS = "CLEAR_CART_SUCCESS";
export const CLEAR_CART_FAIL = "CLEAR_CART_FAIL";
export const CLEAR_CART_RESET = "CLEAR_CART_RESET";

export const TOTAL_CART_REQUEST = "TOTAL_CART_REQUEST";
export const TOTAL_CART_SUCCESS = "TOTAL_CART_SUCCESS";
export const TOTAL_CART_FAIL = "TOTAL_CART_FAIL";
export const TOTAL_CART_RESET = "TOTAL_CART_RESET";
