export const SELLER_WITHDRAWAL_REQUEST = "SELLER_WITHDRAWAL_REQUEST";
export const SELLER_WITHDRAWAL_SUCCESS = "SELLER_WITHDRAWAL_SUCCESS";
export const SELLER_WITHDRAWAL_FAIL = "SELLER_WITHDRAWAL_FAIL";
export const SELLER_WITHDRAWAL_RESET = "SELLER_WITHDRAWAL_RESET";

export const SELLER_GET_WITHDRAWALS_REQUEST = "SELLER_GET_WITHDRAWALS_REQUEST";
export const SELLER_GET_WITHDRAWALS_SUCCESS = "SELLER_GET_WITHDRAWALS_SUCCESS";
export const SELLER_GET_WITHDRAWALS_FAIL = "SELLER_GET_WITHDRAWALS_FAIL";
export const SELLER_GET_WITHDRAWALS_RESET = "SELLER_GET_WITHDRAWALS_RESET";

export const SELLER_GET_WITHDRAWAL_STATS_REQUEST =
  "SELLER_GET_WITHDRAWAL_STATS_REQUEST";
export const SELLER_GET_WITHDRAWAL_STATS_SUCCESS =
  "SELLER_GET_WITHDRAWAL_STATS_SUCCESS";
export const SELLER_GET_WITHDRAWAL_STATS_FAIL =
  "SELLER_GET_WITHDRAWAL_STATS_FAIL";
export const SELLER_GET_WITHDRAWAL_STATS_RESET =
  "SELLER_GET_WITHDRAWAL_STATS_RESET";

export const SELLER_GET_WITHDRAWAL_BYID_REQUEST =
  "SELLER_GET_WITHDRAWAL_BYID_REQUEST";
export const SELLER_GET_WITHDRAWAL_BYID_SUCCESS =
  "SELLER_GET_WITHDRAWAL_BYID_SUCCESS";
export const SELLER_GET_WITHDRAWAL_BYID_FAIL =
  "SELLER_GET_WITHDRAWAL_BYID_FAIL";
export const SELLER_GET_WITHDRAWAL_BYID_RESET =
  "SELLER_GET_WITHDRAWAL_BYID_RESET";

export const SELLER_DELETE_WITHDRAWAL_REQUEST =
  "SELLER_DELETE_WITHDRAWAL_REQUEST";
export const SELLER_DELETE_WITHDRAWAL_SUCCESS =
  "SELLER_DELETE_WITHDRAWAL_SUCCESS";
export const SELLER_DELETE_WITHDRAWAL_FAIL = "SELLER_DELETE_WITHDRAWAL_FAIL";
export const SELLER_DELETE_WITHDRAWAL_RESET = "SELLER_DELETE_WITHDRAWAL_RESET";
