export const SELLER_GET_BALANCE_REQUEST = "SELLER_GET_BALANCE_REQUEST";
export const SELLER_GET_BALANCE_SUCCESS = "SELLER_GET_BALANCE_SUCCESS";
export const SELLER_GET_BALANCE_FAIL = "SELLER_GET_BALANCE_FAIL";
export const SELLER_GET_BALANCE_RESET = "SELLER_GET_BALANCE_RESET";

export const SELLER_ADD_BANK_REQUEST = "SELLER_ADD_BANK_REQUEST";
export const SELLER_ADD_BANK_SUCCESS = "SELLER_ADD_BANK_SUCCESS";
export const SELLER_ADD_BANK_FAIL = "SELLER_ADD_BANK_FAIL";
export const SELLER_ADD_BANK_RESET = "SELLER_ADD_BANK_RESET";

export const SELLER_DELETE_BANK_REQUEST = "SELLER_DELETE_BANK_REQUEST";
export const SELLER_DELETE_BANK_SUCCESS = "SELLER_DELETE_BANK_SUCCESS";
export const SELLER_DELETE_BANK_FAIL = "SELLER_DELETE_BANK_FAIL";
export const SELLER_DELETE_BANK_RESET = "SELLER_DELETE_BANK_RESET";

export const SELLER_ADD_PAYPAL_REQUEST = "SELLER_ADD_PAYPAL_REQUEST";
export const SELLER_ADD_PAYPAL_SUCCESS = "SELLER_ADD_PAYPAL_SUCCESS";
export const SELLER_ADD_PAYPAL_FAIL = "SELLER_ADD_PAYPAL_FAIL";
export const SELLER_ADD_PAYPAL_RESET = "SELLER_ADD_PAYPAL_RESET";

export const SELLER_DELETE_PAYPAL_REQUEST = "SELLER_DELETE_PAYPAL_REQUEST";
export const SELLER_DELETE_PAYPAL_SUCCESS = "SELLER_DELETE_PAYPAL_SUCCESS";
export const SELLER_DELETE_PAYPAL_FAIL = "SELLER_DELETE_PAYPAL_FAIL";
export const SELLER_DELETE_PAYPAL_RESET = "SELLER_DELETE_PAYPAL_RESET";

export const SELLER_ADD_PAYONEER_REQUEST = "SELLER_ADD_PAYONEER_REQUEST";
export const SELLER_ADD_PAYONEER_SUCCESS = "SELLER_ADD_PAYONEER_SUCCESS";
export const SELLER_ADD_PAYONEER_FAIL = "SELLER_ADD_PAYONEER_FAIL";
export const SELLER_ADD_PAYONEER_RESET = "SELLER_ADD_PAYONEER_RESET";

export const SELLER_DELETE_PAYONEER_REQUEST = "SELLER_DELETE_PAYONEER_REQUEST";
export const SELLER_DELETE_PAYONEER_SUCCESS = "SELLER_DELETE_PAYONEER_SUCCESS";
export const SELLER_DELETE_PAYONEER_FAIL = "SELLER_DELETE_PAYONEER_FAIL";
export const SELLER_DELETE_PAYONEER_RESET = "SELLER_DELETE_PAYONEER_RESET";
