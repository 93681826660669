export const ADMIN_CREATE_PRODUCT_REQUEST = "ADMIN_CREATE_PRODUCT_REQUEST";
export const ADMIN_CREATE_PRODUCT_SUCCESS = "ADMIN_CREATE_PRODUCT_SUCCESS";
export const ADMIN_CREATE_PRODUCT_FAIL = "ADMIN_CREATE_PRODUCT_FAIL";
export const ADMIN_CREATE_PRODUCT_RESET = "ADMIN_CREATE_PRODUCT_RESET";

export const ADMIN_UPDATE_PRODUCT_REQUEST = "ADMIN_UPDATE_PRODUCT_REQUEST";
export const ADMIN_UPDATE_PRODUCT_SUCCESS = "ADMIN_UPDATE_PRODUCT_SUCCESS";
export const ADMIN_UPDATE_PRODUCT_FAIL = "ADMIN_UPDATE_PRODUCT_FAIL";
export const ADMIN_UPDATE_PRODUCT_RESET = "ADMIN_UPDATE_PRODUCT_RESET";

export const ADMIN_DELETE_PRODUCT_REQUEST = "ADMIN_DELETE_PRODUCT_REQUEST";
export const ADMIN_DELETE_PRODUCT_SUCCESS = "ADMIN_DELETE_PRODUCT_SUCCESS";
export const ADMIN_DELETE_PRODUCT_FAIL = "ADMIN_DELETE_PRODUCT_FAIL";
export const ADMIN_DELETE_PRODUCT_RESET = "ADMIN_DELETE_PRODUCT_RESET";

export const ADMIN_GETBYID_PRODUCT_REQUEST = "ADMIN_GETBYID_PRODUCT_REQUEST";
export const ADMIN_GETBYID_PRODUCT_SUCCESS = "ADMIN_GETBYID_PRODUCT_SUCCESS";
export const ADMIN_GETBYID_PRODUCT_FAIL = "ADMIN_GETBYID_PRODUCT_FAIL";
export const ADMIN_GETBYID_PRODUCT_RESET = "ADMIN_GETBYID_PRODUCT_RESET";

export const ADMIN_GET_ALL_PRODUCT_REQUEST = "ADMIN_GETBYALL_PRODUCT_REQUEST";
export const ADMIN_GET_ALL_PRODUCT_SUCCESS = "ADMIN_GETBYALL_PRODUCT_SUCCESS";
export const ADMIN_GET_ALL_PRODUCT_FAIL = "ADMIN_GETBYALL_PRODUCT_FAIL";
export const ADMIN_GET_ALL_PRODUCT_RESET = "ADMIN_GETBYALL_PRODUCT_RESET";
