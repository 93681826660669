export const SELLER_GET_PRODUCTS_ORDERS_REQUEST =
  "SELLER_GET_PRODUCTS_ORDERS_REQUEST";
export const SELLER_GET_PRODUCTS_ORDERS_SUCCESS =
  "SELLER_GET_PRODUCTS_ORDERS_SUCCESS";
export const SELLER_GET_PRODUCTS_ORDERS_FAIL =
  "SELLER_GET_PRODUCTS_ORDERS_FAIL";
export const SELLER_GET_PRODUCTS_ORDERS_RESET =
  "SELLER_GET_PRODUCTS_ORDERS_RESET";

export const SELLER_GET_PRODUCTS_ORDER_BYID_REQUEST =
  "SELLER_GET_PRODUCTS_ORDER_BYID_REQUEST";
export const SELLER_GET_PRODUCTS_ORDER_BYID_SUCCESS =
  "SELLER_GET_PRODUCTS_ORDER_BYID_SUCCESS";
export const SELLER_GET_PRODUCTS_ORDER_BYID_FAIL =
  "SELLER_GET_PRODUCTS_ORDER_BYID_FAIL";
export const SELLER_GET_PRODUCTS_ORDER_BYID_RESET =
  "SELLER_GET_PRODUCTS_ORDER_BYID_RESET";

export const SELLER_UPDATE_PRODUCTS_ORDER_REQUEST =
  "SELLER_UPDATE_PRODUCTS_ORDER_REQUEST";
export const SELLER_UPDATE_PRODUCTS_ORDER_SUCCESS =
  "SELLER_UPDATE_PRODUCTS_ORDER_SUCCESS";
export const SELLER_UPDATE_PRODUCTS_ORDER_FAIL =
  "SELLER_UPDATE_PRODUCTS_ORDER_FAIL";
export const SELLER_UPDATE_PRODUCTS_ORDER_RESET =
  "SELLER_UPDATE_PRODUCTS_ORDER_RESET";

export const SELLER_DELETE_PRODUCTS_ORDER_BYID_REQUEST =
  "SELLER_DELETE_PRODUCTS_ORDER_BYID_REQUEST";
export const SELLER_DELETE_PRODUCTS_ORDER_BYID_SUCCESS =
  "SELLER_DELETE_PRODUCTS_ORDER_BYID_SUCCESS";
export const SELLER_DELETE_PRODUCTS_ORDER_BYID_FAIL =
  "SELLER_DELETE_PRODUCTS_ORDER_BYID_FAIL";
export const SELLER_DELETE_PRODUCTS_ORDER_BYID_RESET =
  "SELLER_DELETE_PRODUCTS_ORDER_BYID_RESET";
