export const ADMIN_GET_SERVICE_ORDERS_REQUEST =
  "ADMIN_GET_SERVICE_ORDERS_REQUEST";
export const ADMIN_GET_SERVICE_ORDERS_SUCCESS =
  "ADMIN_GET_SERVICE_ORDERS_SUCCESS";
export const ADMIN_GET_SERVICE_ORDERS_FAIL = "ADMIN_GET_SERVICE_ORDERS_FAIL";
export const ADMIN_GET_SERVICE_ORDERS_RESET = "ADMIN_GET_SERVICE_ORDERS_RESET";

export const ADMIN_GET_SERVICE_ORDER_BYID_REQUEST =
  "ADMIN_GET_SERVICE_ORDER_BYID_REQUEST";
export const ADMIN_GET_SERVICE_ORDER_BYID_SUCCESS =
  "ADMIN_GET_SERVICE_ORDER_BYID_SUCCESS";
export const ADMIN_GET_SERVICE_ORDER_BYID_FAIL =
  "ADMIN_GET_SERVICE_ORDER_BYID_FAIL";
export const ADMIN_GET_SERVICE_ORDER_BYID_RESET =
  "ADMIN_GET_SERVICE_ORDER_BYID_RESET";

export const ADMIN_DELETE_SERVICE_ORDER_BYID_REQUEST =
  "ADMIN_DELETE_SERVICE_ORDER_BYID_REQUEST";
export const ADMIN_DELETE_SERVICE_ORDER_BYID_SUCCESS =
  "ADMIN_DELETE_SERVICE_ORDER_BYID_SUCCESS";
export const ADMIN_DELETE_SERVICE_ORDER_BYID_FAIL =
  "ADMIN_DELETE_SERVICE_ORDER_BYID_FAIL";
export const ADMIN_DELETE_SERVICE_ORDER_BYID_RESET =
  "ADMIN_DELETE_SERVICE_ORDER_BYID_RESET";
