export const ADMIN_GET_UNAPPROVED_TESTIMONIAL_REQUEST =
  "ADMIN_GET_UNAPPROVED_TESTIMONIAL_REQUEST";
export const ADMIN_GET_UNAPPROVED_TESTIMONIAL_SUCCESS =
  "ADMIN_GET_UNAPPROVED_TESTIMONIAL_SUCCESS";
export const ADMIN_GET_UNAPPROVED_TESTIMONIAL_FAIL =
  "ADMIN_GET_UNAPPROVED_TESTIMONIAL_FAIL";
export const ADMIN_GET_UNAPPROVED_TESTIMONIAL_RESET =
  "ADMIN_GET_UNAPPROVED_TESTIMONIAL_RESET";

export const ADMIN_APPROVE_TESTIMONIAL_REQUEST =
  "ADMIN_APPROVE_TESTIMONIAL_REQUEST";
export const ADMIN_APPROVE_TESTIMONIAL_SUCCESS =
  "ADMIN_APPROVE_TESTIMONIAL_SUCCESS";
export const ADMIN_APPROVE_TESTIMONIAL_FAIL = "ADMIN_APPROVE_TESTIMONIAL_FAIL";
export const ADMIN_APPROVE_TESTIMONIAL_RESET =
  "ADMIN_APPROVE_TESTIMONIAL_RESET";

export const ADMIN_UPDATE_TESTIMONIAL_REQUEST =
  "ADMIN_UPDATE_TESTIMONIAL_REQUEST";
export const ADMIN_UPDATE_TESTIMONIAL_SUCCESS =
  "ADMIN_UPDATE_TESTIMONIAL_SUCCESS";
export const ADMIN_UPDATE_TESTIMONIAL_FAIL = "ADMIN_UPDATE_TESTIMONIAL_FAIL";
export const ADMIN_UPDATE_TESTIMONIAL_RESET = "ADMIN_UPDATE_TESTIMONIAL_RESET";

export const ADMIN_DELETE_TESTIMONIAL_REQUEST =
  "ADMIN_DELETE_TESTIMONIAL_REQUEST";
export const ADMIN_DELETE_TESTIMONIAL_SUCCESS =
  "ADMIN_DELETE_TESTIMONIAL_SUCCESS";
export const ADMIN_DELETE_TESTIMONIAL_FAIL = "ADMIN_DELETE_TESTIMONIAL_FAIL";
export const ADMIN_DELETE_TESTIMONIAL_RESET = "ADMIN_DELETE_TESTIMONIAL_RESET";
