export const ADMIN_GET_GROUPCHATS_REQUEST = "ADMIN_GET_GROUPCHATS_REQUEST";
export const ADMIN_GET_GROUPCHATS_SUCCESS = "ADMIN_GET_GROUPCHATS_SUCCESS";
export const ADMIN_GET_GROUPCHATS_FAIL = "ADMIN_GET_GROUPCHATS_FAIL";
export const ADMIN_GET_GROUPCHATS_RESET = "ADMIN_GET_GROUPCHATS_RESET";

export const ADMIN_CREATE_GROUPCHAT_REQUEST = "ADMIN_CREATE_GROUPCHAT_REQUEST";
export const ADMIN_CREATE_GROUPCHAT_SUCCESS = "ADMIN_CREATE_GROUPCHAT_SUCCESS";
export const ADMIN_CREATE_GROUPCHAT_FAIL = "ADMIN_CREATE_GROUPCHAT_FAIL";
export const ADMIN_CREATE_GROUPCHAT_RESET = "ADMIN_CREATE_GROUPCHAT_RESET";

export const ADMIN_UPDATE_GROUPCHAT_REQUEST = "ADMIN_UPDATE_GROUPCHAT_REQUEST";
export const ADMIN_UPDATE_GROUPCHAT_SUCCESS = "ADMIN_UPDATE_GROUPCHAT_SUCCESS";
export const ADMIN_UPDATE_GROUPCHAT_FAIL = "ADMIN_UPDATE_GROUPCHAT_FAIL";
export const ADMIN_UPDATE_GROUPCHAT_RESET = "ADMIN_UPDATE_GROUPCHAT_RESET";

export const ADMIN_ACTIVATE_GROUPCHAT_REQUEST =
  "ADMIN_ACTIVATE_GROUPCHAT_REQUEST";
export const ADMIN_ACTIVATE_GROUPCHAT_SUCCESS =
  "ADMIN_ACTIVATE_GROUPCHAT_SUCCESS";
export const ADMIN_ACTIVATE_GROUPCHAT_FAIL = "ADMIN_ACTIVATE_GROUPCHAT_FAIL";
export const ADMIN_ACTIVATE_GROUPCHAT_RESET = "ADMIN_ACTIVATE_GROUPCHAT_RESET";

export const ADMIN_SUSPEND_GROUPCHAT_REQUEST =
  "ADMIN_SUSPEND_GROUPCHAT_REQUEST";
export const ADMIN_SUSPEND_GROUPCHAT_SUCCESS =
  "ADMIN_SUSPEND_GROUPCHAT_SUCCESS";
export const ADMIN_SUSPEND_GROUPCHAT_FAIL = "ADMIN_SUSPEND_GROUPCHAT_FAIL";
export const ADMIN_SUSPEND_GROUPCHAT_RESET = "ADMIN_SUSPEND_GROUPCHAT_RESET";

export const ADMIN_DELETE_GROUPCHAT_REQUEST = "ADMIN_DELETE_GROUPCHAT_REQUEST";
export const ADMIN_DELETE_GROUPCHAT_SUCCESS = "ADMIN_DELETE_GROUPCHAT_SUCCESS";
export const ADMIN_DELETE_GROUPCHAT_FAIL = "ADMIN_DELETE_GROUPCHAT_FAIL";
export const ADMIN_DELETE_GROUPCHAT_RESET = "ADMIN_DELETE_GROUPCHAT_RESET";
