export const CREATE_PRODUCT_ORDER_REQUEST = "CREATE_PRODUCT_ORDER_REQUEST";
export const CREATE_PRODUCT_ORDER_SUCCESS = "CREATE_PRODUCT_ORDER_SUCCESS";
export const CREATE_PRODUCT_ORDER_FAIL = "CREATE_PRODUCT_ORDER_FAIL";
export const CREATE_PRODUCT_ORDER_RESET = "CREATE_PRODUCT_ORDER_RESET";

export const GET_PRODUCTS_ORDERS_REQUEST = "GET_PRODUCTS_ORDERS_REQUEST";
export const GET_PRODUCTS_ORDERS_SUCCESS = "GET_PRODUCTS_ORDERS_SUCCESS";
export const GET_PRODUCTS_ORDERS_FAIL = "GET_PRODUCTS_ORDERS_FAIL";
export const GET_PRODUCTS_ORDERS_RESET = "GET_PRODUCTS_ORDERS_RESET";

export const GET_PRODUCTS_ORDER_BYID_REQUEST =
  "GET_PRODUCTS_ORDER_BYID_REQUEST";
export const GET_PRODUCTS_ORDER_BYID_SUCCESS =
  "GET_PRODUCTS_ORDER_BYID_SUCCESS";
export const GET_PRODUCTS_ORDER_BYID_FAIL = "GET_PRODUCTS_ORDER_BYID_FAIL";
export const GET_PRODUCTS_ORDER_BYID_RESET = "GET_PRODUCTS_ORDER_BYID_RESET";

export const GET_PRODUCTS_ORDER_BYORDERID_REQUEST =
  "GET_PRODUCTS_ORDER_BYORDERID_REQUEST";
export const GET_PRODUCTS_ORDER_BYORDERID_SUCCESS =
  "GET_PRODUCTS_ORDER_BYORDERID_SUCCESS";
export const GET_PRODUCTS_ORDER_BYORDERID_FAIL =
  "GET_PRODUCTS_ORDER_BYORDERID_FAIL";
export const GET_PRODUCTS_ORDER_BYORDERID_RESET =
  "GET_PRODUCTS_ORDER_BYORDERID_RESET";
