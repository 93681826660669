export const UPDATE_SHIPPING_DESTINATION_REQUEST =
  "UPDATE_SHIPPING_DESTINATION_REQUEST";
export const UPDATE_SHIPPING_DESTINATION_SUCCESS =
  "UPDATE_SHIPPING_DESTINATION_SUCCESS";
export const UPDATE_SHIPPING_DESTINATION_FAIL =
  "UPDATE_SHIPPING_DESTINATION_FAIL";
export const UPDATE_SHIPPING_DESTINATION_RESET =
  "UPDATE_SHIPPING_DESTINATION_RESET";

export const VIEW_SHIPPING_DESTINATION_REQUEST =
  "VIEW_SHIPPING_DESTINATION_REQUEST";
export const VIEW_SHIPPING_DESTINATION_SUCCESS =
  "VIEW_SHIPPING_DESTINATION_SUCCESS";
export const VIEW_SHIPPING_DESTINATION_FAIL = "VIEW_SHIPPING_DESTINATION_FAIL";
export const VIEW_SHIPPING_DESTINATION_RESET =
  "VIEW_SHIPPING_DESTINATION_RESET";

export const TRACK_DISTANCE_TIME_REQUEST = "TRACK_DISTANCE_TIME_REQUEST";
export const TRACK_DISTANCE_TIME_SUCCESS = "TRACK_DISTANCE_TIME_SUCCESS";
export const TRACK_DISTANCE_TIME_FAIL = "TRACK_DISTANCE_TIME_FAIL";
export const TRACK_DISTANCE_TIME_RESET = "TRACK_DISTANCE_TIME_RESET";
