export const ADMIN_ANALYTICS_TOPSERVICES_REQUEST =
  "ADMIN_ANALYTICS_TOPSERVICES_REQUEST";
export const ADMIN_ANALYTICS_TOPSERVICES_SUCCESS =
  "ADMIN_ANALYTICS_TOPSERVICES_SUCCESS";
export const ADMIN_ANALYTICS_TOPSERVICES_FAIL =
  "ADMIN_ANALYTICS_TOPSERVICES_FAIL";
export const ADMIN_ANALYTICS_TOPSERVICES_RESET =
  "ADMIN_ANALYTICS_TOPSERVICES_RESET";

export const ADMIN_ANALYTICS_TOPPRODUCTS_REQUEST =
  "ADMIN_ANALYTICS_TOPPRODUCTS_REQUEST";
export const ADMIN_ANALYTICS_TOPPRODUCTS_SUCCESS =
  "ADMIN_ANALYTICS_TOPPRODUCTS_SUCCESS";
export const ADMIN_ANALYTICS_TOPPRODUCTS_FAIL =
  "ADMIN_ANALYTICS_TOPPRODUCTS_FAIL";
export const ADMIN_ANALYTICS_TOPPRODUCTS_RESET =
  "ADMIN_ANALYTICS_TOPPRODUCTS_RESET";

export const ADMIN_ANALYTICS_LATEST_PRODUCT_ORDERS_REQUEST =
  "ADMIN_ANALYTICS_LATEST_PRODUCT_ORDERS_REQUEST";
export const ADMIN_ANALYTICS_LATEST_PRODUCT_ORDERS_SUCCESS =
  "ADMIN_ANALYTICS_LATEST_PRODUCT_ORDERS_SUCCESS";
export const ADMIN_ANALYTICS_LATEST_PRODUCT_ORDERS_FAIL =
  "ADMIN_ANALYTICS_LATEST_PRODUCT_ORDERS_FAIL";
export const ADMIN_ANALYTICS_LATEST_PRODUCT_ORDERS_RESET =
  "ADMIN_ANALYTICS_LATEST_PRODUCT_ORDERS_RESET";

export const ADMIN_ANALYTICS_LATEST_SERVICE_ORDERS_REQUEST =
  "ADMIN_ANALYTICS_LATEST_SERVICE_ORDERS_REQUEST";
export const ADMIN_ANALYTICS_LATEST_SERVICE_ORDERS_SUCCESS =
  "ADMIN_ANALYTICS_LATEST_SERVICE_ORDERS_SUCCESS";
export const ADMIN_ANALYTICS_LATEST_SERVICE_ORDERS_FAIL =
  "ADMIN_ANALYTICS_LATEST_SERVICE_ORDERS_FAIL";
export const ADMIN_ANALYTICS_LATEST_SERVICE_ORDERS_RESET =
  "ADMIN_ANALYTICS_LATEST_SERVICE_ORDERS_RESET";

export const ADMIN_ANALYTICS_REVENUE_AND_ORDER_REQUEST =
  "ADMIN_ANALYTICS_REVENUE_AND_ORDER_REQUEST";
export const ADMIN_ANALYTICS_REVENUE_AND_ORDER_SUCCESS =
  "ADMIN_ANALYTICS_REVENUE_AND_ORDER_SUCCESS";
export const ADMIN_ANALYTICS_REVENUE_AND_ORDER_FAIL =
  "ADMIN_ANALYTICS_REVENUE_AND_ORDER_FAIL";
export const ADMIN_ANALYTICS_REVENUE_AND_ORDER_RESET =
  "ADMIN_ANALYTICS_REVENUE_AND_ORDER_RESET";

export const ADMIN_ANALYTICS_VISITORS_AND_CONVERSIONS_REQUEST =
  "ADMIN_ANALYTICS_VISITORS_AND_CONVERSIONS_REQUEST";
export const ADMIN_ANALYTICS_VISITORS_AND_CONVERSIONS_SUCCESS =
  "ADMIN_ANALYTICS_VISITORS_AND_CONVERSIONS_SUCCESS";
export const ADMIN_ANALYTICS_VISITORS_AND_CONVERSIONS_FAIL =
  "ADMIN_ANALYTICS_VISITORS_AND_CONVERSIONS_FAIL";
export const ADMIN_ANALYTICS_VISITORS_AND_CONVERSIONS_RESET =
  "ADMIN_ANALYTICS_VISITORS_AND_CONVERSIONS_RESET";
