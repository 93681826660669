export const USER_GET_SERVICES_REQUEST = "USER_GET_SERVICES_REQUEST";
export const USER_GET_SERVICES_SUCCESS = "USER_GET_SERVICES_SUCCESS";
export const USER_GET_SERVICES_FAIL = "USER_GET_SERVICES_FAIL";
export const USER_GET_SERVICES_RESET = "USER_GET_SERVICES_RESET";

export const USER_GET_NEW_SERVICES_REQUEST = "USER_GET_NEW_SERVICES_REQUEST";
export const USER_GET_NEW_SERVICES_SUCCESS = "USER_GET_NEW_SERVICES_SUCCESS";
export const USER_GET_NEW_SERVICES_FAIL = "USER_GET_NEW_SERVICES_FAIL";
export const USER_GET_NEW_SERVICES_RESET = "USER_GET_NEW_SERVICES_RESET";

export const USER_GET_TOP_RATED_SERVICES_REQUEST =
  "USER_GET_TOP_RATED_SERVICES_REQUEST";
export const USER_GET_TOP_RATED_SERVICES_SUCCESS =
  "USER_GET_TOP_RATED_SERVICES_SUCCESS";
export const USER_GET_TOP_RATED_SERVICES_FAIL =
  "USER_GET_TOP_RATED_SERVICES_FAIL";
export const USER_GET_TOP_RATED_SERVICES_RESET =
  "USER_GET_TOP_RATED_SERVICES_RESET";

export const USER_GET_SERVICE_BY_ID_REQUEST = "USER_GET_SERVICE_BY_ID_REQUEST";
export const USER_GET_SERVICE_BY_ID_SUCCESS = "USER_GET_SERVICE_BY_ID_SUCCESS";
export const USER_GET_SERVICE_BY_ID_FAIL = "USER_GET_SERVICE_BY_ID_FAIL";
export const USER_GET_SERVICE_BY_ID_RESET = "USER_GET_SERVICE_BY_ID_RESET";

export const USER_GET_SERVICES_CATEGORY_REQUEST =
  "USER_GETBYSERVICES_CATEGORY_REQUEST";
export const USER_GET_SERVICES_CATEGORY_SUCCESS =
  "USER_GETBYSERVICES_CATEGORY_SUCCESS";
export const USER_GET_SERVICES_CATEGORY_FAIL =
  "USER_GETBYSERVICES_CATEGORY_FAIL";
export const USER_GET_SERVICES_CATEGORY_RESET = "USER_GETBYALL_CATEGORY_RESET";
