export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";
export const GET_PROFILE_RESET = "GET_PROFILE_RESET";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET";

export const UPDATE_SOCIALS_REQUEST = "UPDATE_SOCIALS_REQUEST";
export const UPDATE_SOCIALS_SUCCESS = "UPDATE_SOCIALS_SUCCESS";
export const UPDATE_SOCIALS_FAIL = "UPDATE_SOCIALS_FAIL";
export const UPDATE_SOCIALS_RESET = "UPDATE_SOCIALS_RESET";

export const UPDATE_COMPANY_DETAILS_REQUEST = "UPDATE_COMPANY_DETAILS_REQUEST";
export const UPDATE_COMPANY_DETAILS_SUCCESS = "UPDATE_COMPANY_DETAILS_SUCCESS";
export const UPDATE_COMPANY_DETAILS_FAIL = "UPDATE_COMPANY_DETAILS_FAIL";
export const UPDATE_COMPANY_DETAILS_RESET = "UPDATE_COMPANY_DETAILS_RESET";

export const GET_PROFILE_DATA_REQUEST = "GET_PROFILE_DATA_REQUEST";
export const GET_PROFILE_DATA_SUCCESS = "GET_PROFILE_DATA_SUCCESS";
export const GET_PROFILE_DATA_FAIL = "GET_PROFILE_DATA_FAIL";
export const GET_PROFILE_DATA_RESET = "GET_PROFILE_DATA_RESET";
