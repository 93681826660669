export const GET_ADMIN_PAYMENTS_REQUEST = "GET_ADMIN_PAYMENTS_REQUEST";
export const GET_ADMIN_PAYMENTS_SUCCESS = "GET_ADMIN_PAYMENTS_SUCCESS";
export const GET_ADMIN_PAYMENTS_FAIL = "GET_ADMIN_PAYMENTS_FAIL";
export const GET_ADMIN_PAYMENTS_RESET = "GET_ADMIN_PAYMENTS_RESET";

export const GET_ADMIN_PAYMENTS_BYID_REQUEST =
  "GET_ADMIN_PAYMENTS_BYID_REQUEST";
export const GET_ADMIN_PAYMENTS_BYID_SUCCESS =
  "GET_ADMIN_PAYMENTS_BYID_SUCCESS";
export const GET_ADMIN_PAYMENTS_BYID_FAIL = "GET_ADMIN_PAYMENTS_BYID_FAIL";
export const GET_ADMIN_PAYMENTS_BYID_RESET = "GET_ADMIN_PAYMENTS_BYID_RESET";
