// for getting list of chats
export const USER_GET_CHATS_REQUEST = "USER_GET_CHATS_REQUEST";
export const USER_GET_CHATS_SUCCESS = "USER_GET_CHATS_SUCCESS";
export const USER_GET_CHATS_FAIL = "USER_GET_CHATS_FAIL";
export const USER_GET_CHATS_RESET = "USER_GET_CHATS_RESET";

export const USER_SEND_MESSAGES_REQUEST = "USER_SEND_MESSAGES_REQUEST";
export const USER_SEND_MESSAGES_SUCCESS = "USER_SEND_MESSAGES_SUCCESS";
export const USER_SEND_MESSAGES_FAIL = "USER_SEND_MESSAGES_FAIL";
export const USER_SEND_MESSAGES_RESET = "USER_SEND_MESSAGES_RESET";

export const USER_GET_CHATS_MESSAGES_REQUEST =
  "USER_GET_CHATS_MESSAGES_REQUEST";
export const USER_GET_CHATS_MESSAGES_SUCCESS =
  "USER_GET_CHATS_MESSAGES_SUCCESS";
export const USER_GET_CHATS_MESSAGES_FAIL = "USER_GET_CHATS_MESSAGES_FAIL";
export const USER_GET_CHATS_MESSAGES_RESET = "USER_GET_CHATS_MESSAGES_RESET";
