export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";
export const GET_NOTIFICATIONS_RESET = "GET_NOTIFICATIONS_RESET";

export const GET_4_NOTIFICATIONS_REQUEST = "GET_4_NOTIFICATIONS_REQUEST";
export const GET_4_NOTIFICATIONS_SUCCESS = "GET_4_NOTIFICATIONS_SUCCESS";
export const GET_4_NOTIFICATIONS_FAIL = "GET_4_NOTIFICATIONS_FAIL";
export const GET_4_NOTIFICATIONS_RESET = "GET_4_NOTIFICATIONS_RESET";

export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL";
export const DELETE_NOTIFICATION_RESET = "DELETE_NOTIFICATION_RESET";

export const CREATE_USER_NOTIFICATION_REQUEST =
  "CREATE_USER_NOTIFICATION_REQUEST";
export const CREATE_USER_NOTIFICATION_SUCCESS =
  "CREATE_USER_NOTIFICATION_SUCCESS";
export const CREATE_USER_NOTIFICATION_FAIL = "CREATE_USER_NOTIFICATION_FAIL";
export const CREATE_USER_NOTIFICATION_RESET = "CREATE_USER_NOTIFICATION_RESET";

export const CREATE_SELLER_NOTIFICATION_REQUEST =
  "CREATE_SELLER_NOTIFICATION_REQUEST";
export const CREATE_SELLER_NOTIFICATION_SUCCESS =
  "CREATE_SELLER_NOTIFICATION_SUCCESS";
export const CREATE_SELLER_NOTIFICATION_FAIL =
  "CREATE_SELLER_NOTIFICATION_FAIL";
export const CREATE_SELLER_NOTIFICATION_RESET =
  "CREATE_SELLER_NOTIFICATION_RESET";

export const MARK_NOTIFICATION_SEEN_REQUEST = "MARK_NOTIFICATION_SEEN_REQUEST";
export const MARK_NOTIFICATION_SEEN_SUCCESS = "MARK_NOTIFICATION_SEEN_SUCCESS";
export const MARK_NOTIFICATION_SEEN_FAIL = "MARK_NOTIFICATION_SEEN_FAIL";
export const MARK_NOTIFICATION_SEEN_RESET = "MARK_NOTIFICATION_SEEN_RESET";

export const GET_NOTIFICATION_UNSEEN_COUNT_REQUEST =
  "GET_NOTIFICATION_UNSEEN_COUNT_REQUEST";
export const GET_NOTIFICATION_UNSEEN_COUNT_SUCCESS =
  "GET_NOTIFICATION_UNSEEN_COUNT_SUCCESS";
export const GET_NOTIFICATION_UNSEEN_COUNT_FAIL =
  "GET_NOTIFICATION_UNSEEN_COUNT_FAIL";
export const GET_NOTIFICATION_UNSEEN_COUNT_RESET =
  "GET_NOTIFICATION_UNSEEN_COUNT_RESET";
