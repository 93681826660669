export const ADMIN_WITHDRAWAL_PAYMENT_REQUEST =
  "ADMIN_WITHDRAWAL_PAYMENT_REQUEST";
export const ADMIN_WITHDRAWAL_PAYMENT_SUCCESS =
  "ADMIN_WITHDRAWAL_PAYMENT_SUCCESS";
export const ADMIN_WITHDRAWAL_PAYMENT_FAIL = "ADMIN_WITHDRAWAL_PAYMENT_FAIL";
export const ADMIN_WITHDRAWAL_PAYMENT_RESET = "ADMIN_WITHDRAWAL_PAYMENT_RESET";

export const ADMIN_PENDING_WITHDRAWAL_REQUEST =
  "ADMIN_PENDING_WITHDRAWAL_REQUEST";
export const ADMIN_PENDING_WITHDRAWAL_SUCCESS =
  "ADMIN_PENDING_WITHDRAWAL_SUCCESS";
export const ADMIN_PENDING_WITHDRAWAL_FAIL = "ADMIN_PENDING_WITHDRAWAL_FAIL";
export const ADMIN_PENDING_WITHDRAWAL_RESET = "ADMIN_PENDING_WITHDRAWAL_RESET";

export const ADMIN_GET_WITHDRAWALS_REQUEST = "ADMIN_GET_WITHDRAWALS_REQUEST";
export const ADMIN_GET_WITHDRAWALS_SUCCESS = "ADMIN_GET_WITHDRAWALS_SUCCESS";
export const ADMIN_GET_WITHDRAWALS_FAIL = "ADMIN_GET_WITHDRAWALS_FAIL";
export const ADMIN_GET_WITHDRAWALS_RESET = "ADMIN_GET_WITHDRAWALS_RESET";

export const ADMIN_GET_WITHDRAWAL_BYID_REQUEST =
  "ADMIN_GET_WITHDRAWAL_BYID_REQUEST";
export const ADMIN_GET_WITHDRAWAL_BYID_SUCCESS =
  "ADMIN_GET_WITHDRAWAL_BYID_SUCCESS";
export const ADMIN_GET_WITHDRAWAL_BYID_FAIL = "ADMIN_GET_WITHDRAWAL_BYID_FAIL";
export const ADMIN_GET_WITHDRAWAL_BYID_RESET =
  "ADMIN_GET_WITHDRAWAL_BYID_RESET";

export const ADMIN_DELETE_WITHDRAWAL_REQUEST =
  "ADMIN_DELETE_WITHDRAWAL_REQUEST";
export const ADMIN_DELETE_WITHDRAWAL_SUCCESS =
  "ADMIN_DELETE_WITHDRAWAL_SUCCESS";
export const ADMIN_DELETE_WITHDRAWAL_FAIL = "ADMIN_DELETE_WITHDRAWAL_FAIL";
export const ADMIN_DELETE_WITHDRAWAL_RESET = "ADMIN_DELETE_WITHDRAWAL_RESET";
