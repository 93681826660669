export const CREATE_TESTIMONIAL_REQUEST = "CREATE_TESTIMONIAL_REQUEST";
export const CREATE_TESTIMONIAL_SUCCESS = "CREATE_TESTIMONIAL_SUCCESS";
export const CREATE_TESTIMONIAL_FAIL = "CREATE_TESTIMONIAL_FAIL";
export const CREATE_TESTIMONIAL_RESET = "CREATE_TESTIMONIAL_RESET";

export const GET_APPROVED_TESTIMONIAL_REQUEST =
  "GET_APPROVED_TESTIMONIAL_REQUEST";
export const GET_APPROVED_TESTIMONIAL_SUCCESS =
  "GET_APPROVED_TESTIMONIAL_SUCCESS";
export const GET_APPROVED_TESTIMONIAL_FAIL = "GET_APPROVED_TESTIMONIAL_FAIL";
export const GET_APPROVED_TESTIMONIAL_RESET = "GET_APPROVED_TESTIMONIAL_RESET";
