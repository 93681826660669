export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST";
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_FAIL = "ADD_TO_WISHLIST_FAIL";
export const ADD_TO_WISHLIST_RESET = "ADD_TO_WISHLIST_RESET";

export const VIEW_WISHLIST_REQUEST = "VIEW_WISHLIST_REQUEST";
export const VIEW_WISHLIST_SUCCESS = "VIEW_WISHLIST_SUCCESS";
export const VIEW_WISHLIST_FAIL = "VIEW_WISHLIST_FAIL";
export const VIEW_WISHLIST_RESET = "VIEW_WISHLIST_RESET";

export const REMOVE_FROM_WISHLIST_REQUEST = "REMOVE_FROM_WISHLIST_REQUEST";
export const REMOVE_FROM_WISHLIST_SUCCESS = "REMOVE_FROM_WISHLIST_SUCCESS";
export const REMOVE_FROM_WISHLIST_FAIL = "REMOVE_FROM_WISHLIST_FAIL";
export const REMOVE_FROM_WISHLIST_RESET = "REMOVE_FROM_WISHLIST_RESET";
